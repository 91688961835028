export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        id: "",
        company_name: "",
        domain: "",
        rate: "",
        sender_id: ""
      },
      rules: {
        company_name: [{
          required: true,
          message: "Please Enter company name.",
          trigger: "blur"
        }],
        domain: [{
          required: true,
          message: "Please Enter valid domain name.",
          trigger: "blur",
          pattern: "^[a-zA-Z0-9]+([\\-\\.]{1}[a-zA-Z0-9]+)*\\.[a-zA-Z]{2,6}$"
        }],
        rate: [{
          required: true,
          message: "Please Enter valid rate.",
          trigger: "blur"
        }],
        sender_id: [{
          required: true,
          message: "Please Enter valid sender id.",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // this.$emit("update:visible", false);
          this.loading = true;
          this.$store.dispatch("admin/reseller/editResellerConfiguration", this.formData).then(() => {
            this.$emit("update:visible", false);
            this.$message({
              message: "Reseller Configuration Updated Successfully",
              type: "success"
            });
          }).catch(() => {
            this.$message({
              message: "There was an issue declining reseller. Try again later",
              type: "error"
            });
          }).finally(() => {
            this.loading = false;
          });
          this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    visible(val) {
      if (val) {
        var _this$editData$sender;
        this.formData.id = this.editData.id;
        this.formData.company_name = this.editData.company_name;
        this.formData.domain = this.editData.domain;
        this.formData.rate = this.editData.rate;
        this.formData.sender_id = ((_this$editData$sender = this.editData.sender_id) === null || _this$editData$sender === void 0 ? void 0 : _this$editData$sender.sender_id) || "";
      }
    }
  }
};