var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('el-dialog', {
    attrs: {
      "title": "Edit Reseller Information",
      "model": _vm.formData,
      "visible": _vm.isVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.isVisible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "editResellerForm",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.formData
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Company Name",
      "prop": "company_name"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.company_name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "company_name", $$v);
      },
      expression: "formData.company_name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Domain Name",
      "prop": "domain"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.domain,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "domain", $$v);
      },
      expression: "formData.domain"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Rate",
      "prop": "rate"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.rate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "rate", $$v);
      },
      expression: "formData.rate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Sender ID",
      "prop": "sender_id"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.sender_id,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sender_id", $$v);
      },
      expression: "formData.sender_id"
    }
  })], 1), _c('el-form-item', [_c('span', {
    staticClass: "dialog-footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('update:visible', false);
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "loading": _vm.loading,
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm('editResellerForm');
      }
    }
  }, [_vm._v(" Confirm")])], 1)])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };