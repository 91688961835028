var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('el-dialog', {
    attrs: {
      "title": "Decline Reseller",
      "model": _vm.formData,
      "visible": _vm.isVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.isVisible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "declineResellerForm",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.formData
    }
  }, [_c('p', [_vm._v("Are you sure you want to decline this reseller?")]), _c('ul', [_c('li', [_vm._v("Company Name: " + _vm._s(_vm.formData.company_name))]), _c('li', [_vm._v("Domain: " + _vm._s(_vm.declineData.domain))]), _c('li', [_vm._v("Rate: " + _vm._s(_vm.formData.rate))]), _c('li', [_vm._v("Sender ID: " + _vm._s(_vm.formData.sender_id || ""))])]), _c('el-form-item', [_c('span', {
    staticClass: "dialog-footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('update:visible', false);
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "loading": _vm.loading,
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm('declineResellerForm');
      }
    }
  }, [_vm._v(" Decline")])], 1)])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };