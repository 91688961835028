import moment from "moment";
import $ from "jquery";
import store from "@/state/store.js";
import editReseller from "../modals/editReseller.vue";
import declineReseller from "../modals/declineReseller.vue";
import appConfig from "@/app.config";
export default {
  components: {
    editReseller,
    declineReseller
  },
  page: {
    title: "Resellers",
    meta: [{
      name: "description",
      content: appConfig.description
    }]
  },
  name: "admin-resellers",
  data() {
    return {
      loading: false,
      loadinSubmitBtn: false,
      success: "",
      errors: "",
      payload: {
        type: ""
      },
      formData: {
        index: "",
        id: "",
        rate: ""
      },
      page: 1,
      pageSize: 20,
      total: 0,
      search: "",
      editResellerVisible: false,
      editData: {},
      declineResellerVisible: false,
      declineData: {}
    };
  },
  methods: {
    closeSuccess() {
      this.success = null;
      this.errors = null;
      $("#actionModal").modal("hide");
    },
    hideModal() {
      this.success = null;
      this.errors = null;
      $("#actionModal").modal("hide");
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    url() {
      return process.env.VUE_APP_ENGINE_URL;
    },
    openActionModal() {
      let element = this.$refs.actionModal.$el;
      $(element).modal("show");
    },
    submitAction() {
      this.loadinSubmitBtn = true;
      return store.dispatch("admin/reseller/confirmReseller", this.formData).then(message => {
        this.success = message;
      }).catch(err => {
        var _err$response;
        if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loadinSubmitBtn = false);
    },
    confirmAccount(message) {
      this.formData.id = message.row.id;
      this.formData.rate = message.row.rate;
      this.formData.index = message.$index;
      $("#actionModal").modal("show");
    },
    editResellerData(data) {
      this.editData = data.row;
      this.editResellerVisible = true;
    },
    declineResellerData(data) {
      this.declineData = data.row;
      this.declineResellerVisible = true;
    }
  },
  computed: {
    tableData() {
      return store.getters["admin/reseller/resellers"];
    },
    searchingTableData() {
      if (!this.search) {
        this.total = this.tableData.length;
        return this.tableData;
      }
      this.page = 1;
      return this.tableData.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()) || data.amount == this.search);
    },
    displayData() {
      this.total = this.searchingTableData.length;
      return this.searchingTableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    }
  },
  mounted() {
    this.loading = true;
    if (this.$route.path.includes("complete")) {
      store.dispatch("admin/reseller/getCompletedResellers").finally(() => this.loading = false);
      return;
    }
    if (this.$route.path.includes("pending")) {
      store.dispatch("admin/reseller/getPendingResellers").finally(() => this.loading = false);
      return;
    }
    if (this.$route.path.includes("declined")) {
      store.dispatch("admin/reseller/getDeclinedResellers").finally(() => this.loading = false);
      return;
    }
  }
};